@import 'reset.less';

@background: #FBF0D9;
@text: #5F4B32;

body {
	//font-family: 'Noto Sans', sans-serif;
	font-family: 'Cabin', sans-serif;
	background: @background;
	color: @text;
}

#auth-login {
	margin-top: 100px;
}

#header {
	display: flex;
	position: fixed;
	z-index: 100;
	top: 0px;
	left: 0px;
	right: 0px;
	padding: 58px 20px 10px;
	background-color: @background;
	border-bottom: 1px solid fade(@text, 20%);

	.title {
		flex: 1;
		font-size: 22px;
		font-weight: bold;
		text-align: center;
	}

	.right-action {
		padding: 5px 10px;
		border: 1px solid fade(@text, 20%);
		border-radius: 5px;

		&:hover {
			background-color: fade(@text, 20%);
		}
	}
}

#inner-frame {
	margin-top: 115px;
	margin-bottom: 110px;

	.page {
		padding: 20px;
	}

	#site-root & {
		margin-top: 0px;
	}
}

#footer {
	display: flex;
	position: fixed;
	z-index: 100;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 10px 20px 30px;
	background: @background;
	border-top: 1px solid fade(@text, 20%);
	font-size: 22px;

	.footer-item {
		flex: 1;
		text-align: center;
		color: fade(@text, 20%);

		&.active {
			color: @text;
		}
	}
}

.episode {
	display: flex;
	margin-bottom: 15px;

	.episode-podcast {
		font-size: 12px;
	}

	.episode-image {
		flex: 0 0 40px;
		margin-right: 10px;
		overflow: hidden;
		padding-top: 4px;

		img {
			border-radius: 5px;
		}
	}

	.episode-title {
		font-weight: bold;
		font-size: 20px;
		border-bottom: 1px solid fade(@text, 20%);
		padding-bottom: 15px;
	}
}

.podcast-list {

	.podcast {
		display: flex;
		margin-bottom: 15px;

		.podcast-image {
			flex: 0 0 40px;
			margin-right: 10px;
			overflow: hidden;
			padding-top: 4px;

			img {
				border-radius: 5px;
			}
		}

		.podcast-info {
			border-bottom: 1px solid fade(@text, 20%);
			padding-bottom: 15px;

			.podcast-author {
				font-size: 12px;
			}

			.podcast-title {
				font-weight: bold;
				font-size: 20px;
			}
		}
	}

}

#podcast-page {
	.podcast {
		display: flex;
		margin-bottom: 15px;

		.podcast-image {
			flex: 0 0 40px;
			margin-right: 10px;
			overflow: hidden;
			padding-top: 4px;

			img {
				border-radius: 5px;
			}
		}

		.podcast-info {
			border-bottom: 1px solid fade(@text, 20%);
			padding-bottom: 15px;

			.podcast-author {
				font-size: 12px;
			}

			.podcast-title {
				padding: 0px;
				font-weight: bold;
				font-size: 20px;
			}
		}
	}

	.podcast-episodes {
		.episode {
			display: flex;
			margin-bottom: 15px;

			.episode-title {
				font-weight: bold;
				font-size: 14px;
				border: none;
				padding: 0px;
			}

			.episode-text {
				font-size: 12px;
				border-bottom: 1px solid fade(@text, 20%);
				padding-bottom: 15px;
			}
		}
	
	}
}

.transcript-line {
	margin-top: 15px;

	.transcript-speaker {
		font-weight: bold;

		span {
			font-weight: normal;
			font-size: 12px;
		}
	}

	.transcript-content {
		span.current-word {
			background-color: fade(@text, 20%);
			border-radius: 5px;
			padding: 0 0 0 3px;
			margin: 0 0 0 -3px;
		}
	}

	&.ad {
		position: relative;
		background: lightgoldenrodyellow;
		padding: 5px 8px;
		margin: 5px -8px;
		border-radius: 5px;

		&::after {
			position: absolute;
			top: 3px;
			right: 5px;
			content: 'AD';
			font-size: 16px;
			font-weight: bold;
			color: fade(@text, 20%);
		}
	}
}

.search-block {
	display: flex;
	margin: 0px 0px 20px;
	padding: 5px;
	background: fade(@text, 10%);
	color: fade(@text, 20%);
	border-radius: 5px;

	i {
		display: block;
		padding: 5px;
	}

	input {
		background: transparent;
		border: none;

		&:focus {
			border: none;
			outline: none;
		}
	}
}
